import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CheckoutContext } from "../../shared/context/checkout-context";
import { Button } from "@/components/ui/button"; // Assuming Select is available
import { Trash2Icon } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Link } from "react-router-dom";
import { ShoppingCartIcon } from "lucide-react";
import { auth } from "../../firebase";
import LoginOr from "cust-conv/loginor";

function Checkout() {
  const navigate = useNavigate();
  const { setCanAccessPlaceOrder } = useContext(CheckoutContext);
  const [products, setProducts] = useState([]);
  const [uniqueStores, setUniqueStores] = useState(new Set());
  const [promoCode, setPromoCode] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [total, setTotal] = useState(0);
  const [discountInfo, setDiscountInfo] = useState({});
  const [showLoginOr, setShowLoginOr] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    fetchProductDetails();
    checkAuthStatus();
    checkGuestStatus();
  }, []);
  const checkAuthStatus = () => {
    auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
    });
  };

  const checkGuestStatus = () => {
    const guestExpiry = localStorage.getItem("guestExpiry");
    if (guestExpiry && new Date(guestExpiry) > new Date()) {
      setShowLoginOr(false);
    }
  };

  const fetchProductDetails = async () => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/products/details`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ productIds: cart.map((item) => item.id) }),
        }
      );
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      const detailedProducts = data.productDetails.map((detail) => {
        const foundItem = cart.find((item) => item.id === detail.id) || {};
        return { ...detail, quantity: foundItem.quantity || 1 };
      });
      setProducts(detailedProducts);
      const storeSet = new Set(
        detailedProducts.map((product) => product.storeId)
      );
      setUniqueStores(storeSet);
      calculateTotals(detailedProducts);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const calculateTotals = (products) => {
    let newSubtotal = products.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    let newTotal = newSubtotal;
    setSubtotal(newSubtotal);
    setTotal(newTotal);
  };

  const handleQuantityChange = (productId, newQuantity) => {
    let updatedProducts = products.map((item) => {
      if (item.id === productId) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setProducts(updatedProducts);
    localStorage.setItem(
      "cart",
      JSON.stringify(
        updatedProducts.map((item) => ({
          id: item.id,
          quantity: item.quantity,
        }))
      )
    );
    calculateTotals(updatedProducts);
  };

  const handleRemoveItem = (productId) => {
    let updatedProducts = products.filter((item) => item.id !== productId);
    setProducts(updatedProducts);
    localStorage.setItem(
      "cart",
      JSON.stringify(
        updatedProducts.map((item) => ({
          id: item.id,
          quantity: item.quantity,
        }))
      )
    );
    calculateTotals(updatedProducts);
  };

  const applyPromoCode = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/campaigns/validate-promo`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            promoCode,
            storeIds: Array.from(uniqueStores),
          }),
        }
      );
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      if (data && uniqueStores.has(data.storeId)) {
        setDiscountInfo(data);
        let discountAmount =
          data.discountType === "percent"
            ? subtotal * (data.discountValue / 100)
            : data.discountValue;
        setTotal(subtotal - discountAmount);
      } else {
        console.error("Discount not applicable.");
        setDiscountInfo({});
      }
    } catch (error) {
      console.error("Error applying promo code:", error);
    }
  };

  const goToPlaceOrder = () => {
    if (isLoggedIn) {
      setCanAccessPlaceOrder(true);
      navigate("/placeorder", { state: { discountInfo } });
    } else {
      setShowLoginOr(true);
    }
  };
  const handleGuestCheckout = () => {
    const expiryDate = new Date();
    expiryDate.setHours(expiryDate.getHours() + 24);
    localStorage.setItem("guestExpiry", expiryDate.toISOString());
    setShowLoginOr(false);
    setCanAccessPlaceOrder(true);
    navigate("/placeorder", { state: { discountInfo } });
  };

  if (showLoginOr) {
    return <LoginOr onGuestCheckout={handleGuestCheckout} />;
  }
  return (
    <div className="container mx-auto py-12 px-4 md:px-6">
      {products.length === 0 ? (
        <div className="flex  flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-md text-center">
            <ShoppingCartIcon className="mx-auto h-12 w-12 text-primary" />
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
              Your cart is empty
            </h1>
            <p className="mt-4 text-muted-foreground">
              Looks like you haven't added any items to your cart yet. Start
              shopping to fill it up!
            </p>
            <div className="mt-6">
              <Link
                to="/"
                className="inline-flex text-white items-center rounded-md bg-black px-4 py-2 text-sm font-medium text-primary-foreground shadow-sm transition-colors hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                prefetch={false}
              >
                Continue Shopping
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          <h1 className="text-2xl font-bold mb-8">Your Cart</h1>

          {uniqueStores.size > 1 && (
            <div className="alert alert-warning">
              Items in your cart are from multiple stores. You may receive
              multiple shipments.
            </div>
          )}
          <div className="grid gap-8">
            <div className="grid gap-6">
              {products.map((product) => (
                <div key={product.id} className="flex items-center gap-4">
                  <img
                    src={product.imageUrls[0] || "/default-placeholder.png"}
                    alt={product.title}
                    width={80}
                    height={80}
                    className="object-cover border rounded-xl flex-shrink-0"
                  />
                  <div className="flex items-center justify-between w-full">
                    <div className="flex flex-col flex-grow">
                      <h3 className="font-semibold text-sm truncate">
                        {product.title}
                      </h3>
                      <p className="text-gray-500 text-sm">
                        ${(product.price * product.quantity).toFixed(2)}
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <Select
                        defaultValue={product.quantity.toString()}
                        onValueChange={(value) =>
                          handleQuantityChange(product.id, parseInt(value))
                        }
                      >
                        <SelectTrigger className="w-[80px]">
                          <SelectValue placeholder="Qty" />
                        </SelectTrigger>
                        <SelectContent>
                          {[...Array(20).keys()].map((n) => (
                            <SelectItem key={n} value={(n + 1).toString()}>
                              {n + 1}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>

                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleRemoveItem(product.id)}
                      >
                        <Trash2Icon className="h-5 w-5" />
                        <span className="sr-only">Remove</span>
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="grid gap-6 border-t pt-6">
              <div className="grid gap-2">
                <div className="mt-8 flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
                  <p className="text-lg font-medium">
                    Total:{" "}
                    <span className="text-primary">${total.toFixed(2)}</span>
                  </p>
                  <div className="flex gap-4">
                    <Link to="/">
                      <Button variant="outline" className="w-full md:w-auto">
                        Continue Shopping
                      </Button>
                    </Link>
                    <Button
                      className="w-full md:w-auto"
                      onClick={goToPlaceOrder}
                    >
                      Proceed to Checkout
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Checkout;
