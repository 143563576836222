import React, { useContext, useState } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import defaultImage from "./loading.webp";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./userProductItem.css";
import Toastify from "toastify-js";
import { Button } from "@/components/ui/button";

const UserProductItem = (props) => {
  const { isLoading } = useHttpClient();
  const [imageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1); // Default quantity is 1

  const addToCartHandler = () => {
    let cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const existingItem = cartItems.find((item) => item.id === props.id);

    if (existingItem) {
      existingItem.quantity += quantity; // Increase by the selected quantity
    } else {
      cartItems.push({ id: props.id, quantity: quantity }); // Add with selected quantity
    }

    localStorage.setItem("cart", JSON.stringify(cartItems));
    Toastify({
      text: `Added ${quantity} items to Cart!`,
      duration: 1500,
      newWindow: true,
      close: true,
      gravity: "top", // `top` or `bottom`
      position: "left", // `left`, `center` or `right`
      stopOnFocus: true, // Prevents dismissing of toast on hover
      style: {
        background: "linear-gradient(to right, #00b09b, #96c93d)",
      },
    }).showToast();
  };

  const navigateToProduct = () => {
    navigate(`/product/more/${props.id}`, {});
  };

  // console.log("HERERE");
  // console.log(props.imageURL[[0]]);
  // console.log(props);

  return (
    <div className="card m-1 h-full flex flex-col bg-white">
      <div className="bg-white rounded-xl overflow-hidden border border-gray flex flex-col h-full">
        {/* Small screens */}
        <div className="flex-grow flex flex-row md:flex-col justify-center items-center">
          <div
            className="w-full md:w-48 bg-white flex justify-center items-center"
            style={{ height: "200px" }}
          >
            <img
              className="max-w-full max-h-full object-contain cursor-pointer"
              onClick={navigateToProduct}
              src={props.imageURL}
              alt={props.title}
              onLoad={() => setImageLoaded(true)}
            />
          </div>
          <div className="p-4 flex flex-col justify-between flex-grow">
            <div>
              <h2
                onClick={navigateToProduct}
                className="text-gray-900 font-bold text-xl mb-2 hover:text-blue-500 cursor-pointer"
              >
                {props.title}
              </h2>
              <p className="text-gray-700 text-base">${props.price}</p>
            </div>
            <div className="flex items-center mt-auto">
              <Button
                className="btn btn-outline-primary rounded-xl px-4 py-2"
                onClick={addToCartHandler}
              >
                Add to Cart
              </Button>
              <div className="ml-4 justify-center items-center">
                <label htmlFor="quantity" className="sr-only">
                  Quantity
                </label>
                <select
                  value={quantity}
                  onChange={(e) => setQuantity(Number(e.target.value))}
                  className="form-select rounded-xl px-4 py-2 ms-2 border"
                >
                  {[...Array(10).keys()].map((num) => (
                    <option key={num + 1} value={num + 1}>
                      {num + 1}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProductItem;
